import React from 'react';
import {} from 'react-bootstrap';
import './ViewToggleComponent.css';

function ViewToggleComponent() {
  return (
    <div></div>
  );
}

export default ViewToggleComponent;